var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"synthesis-comments"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',[_c('h1',{staticClass:"title"},[_vm._v("Actions à réaliser")])]),_c('datatable',{attrs:{"fetch":_vm.$Api.Comment.fetchCommentsSynthesis,"entity":"comment","enableDownload":""},scopedSlots:_vm._u([{key:"project",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Projet"}},[_vm._v(_vm._s(item.projectName))])}},{key:"sheet",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Fiche affaire"}},[_vm._v(_vm._s(item.sheet && item.sheet.name))])}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Date création","type":"date"}},[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))])}},{key:"creator",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Rédacteur"}},[_vm._v(_vm._s(item.creator && item.creator.name))])}},{key:"message",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Commentaire"}},[_vm._v(_vm._s(item.message))])}},{key:"actionName",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Action à réaliser"}},[_vm._v(_vm._s(item.actionName))])}},{key:"responsible",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"En charge de l'action"}},[_vm._v(_vm._s(item.responsible && item.responsible.name))])}},{key:"dueDate",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Date d'échéance","type":"date"}},[_vm._v(_vm._s(_vm._f("date")(item.dueDate)))])}},{key:"active",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Statut de l'action"}},[_vm._v(_vm._s(item.action ? item.active ? 'En cours de réalisation' : 'Clôturée' : ''))])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }