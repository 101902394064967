<template>
  <div class="synthesis-comments">
    <div class="container is-fluid">
      <page-header>
        <h1 class="title">Actions à réaliser</h1>
      </page-header>

      <datatable :fetch="$Api.Comment.fetchCommentsSynthesis" entity="comment" enableDownload>
        <div slot="project" slot-scope="{ item }" title="Projet">{{ item.projectName }}</div>
        <div slot="sheet" slot-scope="{ item }" title="Fiche affaire">{{ item.sheet && item.sheet.name }}</div>
        <div slot="createdAt" slot-scope="{ item }" title="Date création" type="date">{{ item.createdAt | date }}</div>
        <div slot="creator" slot-scope="{ item }" title="Rédacteur">{{ item.creator && item.creator.name }}</div>
        <div slot="message" slot-scope="{ item }" title="Commentaire">{{ item.message }}</div>
        <div slot="actionName" slot-scope="{ item }" title="Action à réaliser">{{ item.actionName }}</div>
        <div slot="responsible" slot-scope="{ item }" title="En charge de l'action">{{ item.responsible && item.responsible.name }}</div>
        <div slot="dueDate" slot-scope="{ item }" title="Date d'échéance" type="date">{{ item.dueDate | date }}</div>
        <div slot="active" slot-scope="{ item }" title="Statut de l'action">{{ item.action ? item.active ? 'En cours de réalisation' : 'Clôturée' : '' }}</div>
      </datatable>
    </div>
  </div>
</template>

<script>
import Datatable from '../../components/elements/Datatable';

export default {
  name: 'synthesis-comments',
  components: {
    Datatable,
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
